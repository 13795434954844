<template>
    <div class="create-ad">
        <!-- 页头 -->
        <div class="page-header">
            <el-row>
                <el-col>
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item>首页</el-breadcrumb-item>
                        <el-breadcrumb-item>合作介绍</el-breadcrumb-item>

                    </el-breadcrumb>
                </el-col>
            </el-row>
        </div>
        <!-- 主要内容 -->
        <div class="main">
            <el-form
                class="form-container"
                ref="form"
                :model="form"
            >
                <el-tag>合作介绍</el-tag>

                <el-form-item
                    class="block-item"
                    :error="$v.form.contents.$error ? '请输入内容' : ''"
                >
                    <tinymce v-model="form.contents" />
                </el-form-item>

                <el-form-item>
                    <el-button
                        type="primary"
                        @click="save"
                    >提交</el-button>

                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import { getdatilRequest, editRequest } from "@/api/sysDictionaries";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  name: "advedit",
  data() {
    return {
      form: {
        id: 0,
        keys: "homeabout",
        keytext: "",
        contents: ""
      }
    };
  },
  components: {
    "image-upload": ImageUpload,
    tinymce: Tinymce
  },
  created() {
    this.getdetail();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getdetail() {
      getdatilRequest({
        keys: this.form.keys
      }).then(res => {
        console.log(res);

        this.form = res.data;
      });
    },
    imageUpload(url) {
      this.form.img = url;
    },
    save() {
      this.$v.form.$touch();
      let params1 = JSON.parse(JSON.stringify(this.form));
      console.log(params1);
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));

        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      contents: {
        required
      }
    }
  }
};
</script>

<style lang="less" scoped>
</style>
