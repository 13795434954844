import request from '@/utils/request'

// 获取列表
export function getListRequest(params) {
  return request({
    url: '/admin/sysDictionaries/getList',
    method: 'get',
    params
  })
}

// 获取详情信息
export function getdatilRequest(params) {
  return request({
    url: '/admin/sysDictionaries/getdatil',
    method: 'get',
    params
  })
}

 
// 修改信息
export function editRequest(data) {
  return request({
    url: '/admin/sysDictionaries/edit',
    method: 'post',
    data
  })
}

 